
























import { computed, defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { coreApi } from "@/lib/backend";
import Button from "@/lib/pfg/vue2/button/Button.vue";
import { cx } from "@/lib/style/cva.config";

export default defineComponent({
	components: { Button },
	props: {
		memberId: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		// FUTURE: This is currently disabled because of the 404 on the query
		const shown = false;

		const { data } = useQuery({
			queryKey: ["member", props.memberId, "notification-preferences"] as const,
			queryFn: async (context) =>
				await coreApi.members
					.memberNotificationPreferencesControllerGetNotificationPreferences(context.queryKey[1], {
						signal: context.signal,
					})
					.then((response) => response.data),
		});

		const isActive = computed(() => !!data.value?.pausedUntil);

		return { shown, isActive, cx };
	},
});
